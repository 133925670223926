import React, { memo } from 'react'
import { AkaTypography } from '../index'
import { TagWrapper, Logo, HeadSectionWrapper } from './styles'
import { CustomTag } from '../../modules/catalog/components/styles'
import ReactHtmlParser from 'react-html-parser'
import { Breadcrumb } from 'antd'
import { ThemeColorName } from 'components/themeProvider/theme'

export type PartialChapterType = {
	pathFromRoot: string[]
}

export type Tag = {
	text: string
	backgroundColor: ThemeColorName
}

export interface HeadSection {
	id: string
	title: string
	tags: string[] | Tag[]
	chapter?: PartialChapterType
	logo?: {
		alt: string
		url: string
	} | null
}

const styles = {
	textDecorationStyle: { textDecoration: 'underline' },
	marginZero: { margin: 0 },
	paddingToRight: { paddingRight: 26 },
	tagTypography: { marginBottom: 0, padding: 5 },
	iconWrapper: { display: 'flex', marginRight: 8 }
}

const displayTags = (tagElement: string | Tag) => {
	const tagText = typeof tagElement === 'object' ? tagElement.text : tagElement
	const backgroundColor =
		typeof tagElement === 'object' && tagElement.backgroundColor
			? tagElement.backgroundColor
			: undefined

	return (
		<div key={tagText} style={styles.iconWrapper}>
			<CustomTag $backgroundColor={backgroundColor}>
				<AkaTypography
					data-testid={`item-tag-${tagText}`}
					variant="body3"
					style={styles.tagTypography}
				>
					{ReactHtmlParser(tagText)}
				</AkaTypography>
			</CustomTag>
		</div>
	)
}

const HeadSection = memo(({ title, tags, id, chapter, logo }: HeadSection) => (
	<HeadSectionWrapper>
		<div>
			<AkaTypography
				variant="h3"
				data-testid={`headline-${id}`}
				style={{ ...styles.marginZero, ...styles.paddingToRight }}
			>
				{ReactHtmlParser(title)}
			</AkaTypography>

			{chapter && chapter.pathFromRoot && (
				<Breadcrumb data-testid={`chapters-path-${id}`}>
					{chapter.pathFromRoot.map(title => (
						<Breadcrumb.Item key={title}>{title}</Breadcrumb.Item>
					))}
				</Breadcrumb>
			)}
			{tags && (
				<TagWrapper>
					{tags.map(tag => {
						return (
							tag &&
							tag !== 'NoneType' &&
							(typeof tag === 'string' ||
								(typeof tag === 'object' && Object.keys(tag).length > 0)) &&
							displayTags(tag)
						)
					})}
				</TagWrapper>
			)}
		</div>
		{logo && <Logo src={logo.url} alt={logo.alt} preview={false} />}
	</HeadSectionWrapper>
))

export default HeadSection
