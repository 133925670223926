export default {
	login: 'Anmelden',
	loginHeadline:
		'Willkommen im AKA-Portal der Hausärztlichen Vertragsgemeinschaft AG',
	passwordForget: 'Passwort vergessen',
	requestAccount: 'Benutzerregistrierung anfordern',
	email: 'E-Mail-Adresse',
	password: 'Passwort',
	emailRequired: 'Geben Sie bitte eine E-Mail-Adresse an.',
	passwordRequired: 'Passwort ist erforderlich.',
	invalidCredentials: 'Ungültige Anmeldeinformationen',
	validations: {
		email: 'Eine gültige E-Mail-Adresse ist erforderlich.'
	},
	footer: {
		company: 'HÄVG Rechenzentrum GmbH',
		impressum: 'Impressum'
	},
	passwordRequestPage: {
		headline:
			'Bitte geben Sie Ihre E-Mail-Adresse ein, um ein neues Passwort anzufordern. Sie erhalten anschließend eine E-Mail mit weiteren Anweisungen zum Zurücksetzen Ihres Passworts.'
	},
	passwordResetRequestForm: {
		messages: {
			success:
				'Bitte überprüfen Sie Ihr Postfach inkl. des Spam-Filters für weitere Anweisungen',
			error: 'Leider ist etwas schief gelaufen. Versuchen Sie es später erneut'
		}
	},
	passwordResetConfirmPage: {
		headline:
			'Bitte geben Sie Ihr neues Passwort ein, um Ihr Konto wiederherzustellen.'
	},
	passwordResetConfirmForm: {
		messages: {
			success:
				'Ihr Passwort wurde erfolgreich geändert. Sie können sich nun mit dem neuen Passwort anmelden',
			error: 'Ihr Passwort konnte nicht geändert werden - {{message}}'
		}
	},
	backToLogin: 'Zurück zur Anmeldung'
}
