import React, { useCallback, useMemo, useState } from 'react'
import { Checkbox, Col, Modal, Row, Form } from 'antd'
import { AkaButton, AkaTypography } from 'components'
import { useTranslation } from 'react-i18next'
import { useUser } from 'providers'
import {
	ATTACHMENT_OPTIONS,
	DOWNLOAD_FILES_OPTIONS,
	DOWNLOAD_FILES_OPTIONS_VALUES
} from 'modules/core/constants'
import { ReleaseType, UserType } from '../../../generated/types'
import { ValidateStatus } from 'antd/lib/form/FormItem'

const styles = {
	checkboxGroup: {
		width: '100%'
	},
	formItem: {
		marginBottom: 0,
		fontFamily: 'SegoeUI, sans-serif',
		fontWeight: 400
	},
	attachmentOptions: { padding: '0 24px' },
	noMargin: { margin: 0 }
}

type checkedListConfiguration = {
	checked: string[]
	errorMsg: string | null
	validateStatus: ValidateStatus
}

interface DownloadDialog {
	handleSubmit: (checkedList: string[], attachmentOption: number) => void
	handleClose: () => void
	sending?: boolean
	open?: boolean
	release: ReleaseType
}

export const DownloadDialog = ({
	handleSubmit,
	handleClose,
	sending = false,
	open = false,
	release
}: DownloadDialog) => {
	const [t] = useTranslation()
	const { user } = useUser()

	const shouldSkipOption = (
		option: string,
		user: UserType,
		release: ReleaseType
	): boolean => {
		// Always skip PCX files
		if (option === DOWNLOAD_FILES_OPTIONS.INCLUDE_PCX_FILES) {
			return true
		}

		// Skip attachments if the directory doesn't exist
		if (
			!release.isAttachmentsDirExist &&
			option === DOWNLOAD_FILES_OPTIONS.INCLUDE_ATTACHMENTS
		) {
			return true
		}

		// Skip options based on user roles and permissions
		if (!user.isSuperuser) {
			// Skip for users with selected contracts
			const isSelectedContractsOption =
				user.hasSelectedContracts &&
				(option === DOWNLOAD_FILES_OPTIONS.INCLUDE_HELP_FILES ||
					option === DOWNLOAD_FILES_OPTIONS.INCLUDE_BASE_FILE)

			// Skip for Kasse users
			const isKasseUserOption =
				user.isKasseUser &&
				(option === DOWNLOAD_FILES_OPTIONS.INCLUDE_BASE_FILE ||
					option === DOWNLOAD_FILES_OPTIONS.INCLUDE_HELP_FILES ||
					option === DOWNLOAD_FILES_OPTIONS.INCLUDE_SVD_XML_FILES ||
					option ===
						DOWNLOAD_FILES_OPTIONS.INCLUDE_INSPECTION_DOCUMENT_XML_FILES)

			// Skip for VSW users
			const isVswUserOption =
				user.isVswUser &&
				(option ===
					DOWNLOAD_FILES_OPTIONS.INCLUDE_INSPECTION_DOCUMENT_CSV_FILES ||
					option ===
						DOWNLOAD_FILES_OPTIONS.INCLUDE_INSPECTION_DOCUMENT_XML_FILES)

			if (isSelectedContractsOption || isKasseUserOption || isVswUserOption) {
				return true
			}
		}

		// Allow option if no conditions match
		return false
	}

	const cleanedDownloadFileOptions = useMemo(() => {
		return DOWNLOAD_FILES_OPTIONS_VALUES.reduce(
			(accum: string[], currentOption: string) => {
				if (shouldSkipOption(currentOption, user, release)) {
					return accum
				}
				return [...accum, currentOption]
			},
			[]
		)
	}, [release, user])

	const [checkedListConfiguration, setCheckListConfiguration] =
		useState<checkedListConfiguration>({
			checked: [...cleanedDownloadFileOptions],
			errorMsg: null,
			validateStatus: '' as ValidateStatus
		})

	const [selectedAttachmentOption, setSelectedAttachmentOption] =
		useState<boolean>(true)

	const validateCheckedList = (checkedList: string[]) => {
		if (checkedList.length === 0) {
			return {
				validateStatus: 'error' as ValidateStatus,
				errorMsg: t('catalog.downloadDialog.errorMessage')
			}
		}
		return {
			validateStatus: 'success' as ValidateStatus,
			errorMsg: null
		}
	}

	const options = [
		{
			label: t('catalog.downloadDialog.options.pdf'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_AKA_PDF
		},
		{
			label: t('catalog.downloadDialog.options.revisionHistory'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_REVISION_HISTORY_FILE
		},
		{
			label: t('catalog.downloadDialog.options.baseFile'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_BASE_FILE
		},
		{
			label: t('catalog.downloadDialog.options.svd.xml'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_SVD_XML_FILES
		},
		{
			label: t('catalog.downloadDialog.options.attachments.label'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_ATTACHMENTS
		},
		{
			label: t('catalog.downloadDialog.options.helpFiles'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_HELP_FILES
		},
		{
			label: t('catalog.downloadDialog.options.svd.pdf'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_SVD_PDF_FILES
		},
		{
			label: t('catalog.downloadDialog.options.inspectionDocumentXml'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_INSPECTION_DOCUMENT_XML_FILES
		},
		{
			label: t('catalog.downloadDialog.options.inspectionDocumentCsv'),
			value: DOWNLOAD_FILES_OPTIONS.INCLUDE_INSPECTION_DOCUMENT_CSV_FILES
		}
	]

	const renderAttachmentOptions = useMemo(
		() => (
			<div style={styles.attachmentOptions} data-testid="attachment-options">
				{release && release.isAttachmentsDirExist && (
					<Checkbox
						value={DOWNLOAD_FILES_OPTIONS.INCLUDE_PCX_FILES}
						data-testid={DOWNLOAD_FILES_OPTIONS.INCLUDE_PCX_FILES}
					>
						{t(
							'catalog.downloadDialog.options.attachments.options.includePcxFiles'
						)}
					</Checkbox>
				)}
			</div>
		),
		[release, t]
	)

	const onChange = (checkedList: string[]) => {
		const attachmentSelected = !!checkedList.find(
			item => item === DOWNLOAD_FILES_OPTIONS.INCLUDE_ATTACHMENTS
		)

		setSelectedAttachmentOption(attachmentSelected)
		setCheckListConfiguration({
			...validateCheckedList(checkedList),
			checked: checkedList
		})
		setSelectedAttachmentOption(attachmentSelected)
	}

	const onSubmit = () => {
		return handleSubmit(
			checkedListConfiguration.checked,
			ATTACHMENT_OPTIONS.ALL_ATTACHMENTS
		)
	}

	const hideOption = useCallback(
		(value: string) => {
			// Check if attachments directory exists
			const isAttachmentOption =
				release &&
				!release.isAttachmentsDirExist &&
				value === DOWNLOAD_FILES_OPTIONS.INCLUDE_ATTACHMENTS

			// Check restrictions for Kasse users
			const isKasseUserRestricted =
				user.isKasseUser &&
				(value === DOWNLOAD_FILES_OPTIONS.INCLUDE_HELP_FILES ||
					value === DOWNLOAD_FILES_OPTIONS.INCLUDE_BASE_FILE ||
					value === DOWNLOAD_FILES_OPTIONS.INCLUDE_SVD_XML_FILES ||
					value ===
						DOWNLOAD_FILES_OPTIONS.INCLUDE_INSPECTION_DOCUMENT_XML_FILES)

			// Check restrictions for VSW users
			const isVswUserRestricted =
				user.isVswUser &&
				(value ===
					DOWNLOAD_FILES_OPTIONS.INCLUDE_INSPECTION_DOCUMENT_CSV_FILES ||
					value ===
						DOWNLOAD_FILES_OPTIONS.INCLUDE_INSPECTION_DOCUMENT_XML_FILES)

			// Check restrictions for users with selected contracts
			const isSelectedContractsRestricted =
				user.hasSelectedContracts &&
				(value === DOWNLOAD_FILES_OPTIONS.INCLUDE_HELP_FILES ||
					value === DOWNLOAD_FILES_OPTIONS.INCLUDE_BASE_FILE)

			// Combine all conditions
			return (
				isAttachmentOption ||
				(!user.isSuperuser &&
					(isKasseUserRestricted ||
						isVswUserRestricted ||
						isSelectedContractsRestricted))
			)
		},
		[user, release]
	)

	return (
		<Modal
			title={
				<AkaTypography
					gutterbottom={0}
					variant={'bodySegoe2'}
					style={styles.noMargin}
				>
					{t('catalog.downloadDialog.title')}
				</AkaTypography>
			}
			open={open}
			onCancel={handleClose}
			width={600}
			footer={[
				<AkaButton
					key="submit"
					loading={sending}
					onClick={onSubmit}
					type="primary"
					shape="round"
					data-testid="download-button"
					disabled={!!checkedListConfiguration.errorMsg}
				>
					{t('catalog.downloadDialog.sendButton')}
				</AkaButton>
			]}
		>
			<Form data-testid="download-modal">
				<Form.Item
					validateStatus={checkedListConfiguration.validateStatus}
					help={checkedListConfiguration.errorMsg}
					style={styles.formItem}
				>
					<Checkbox.Group
						value={checkedListConfiguration.checked}
						onChange={checkedValue => onChange(checkedValue as string[])}
						style={styles.checkboxGroup}
					>
						{options.map(
							option =>
								!hideOption(option.value) && (
									<Row key={option.value}>
										<Col>
											<Checkbox value={option.value} data-testid={option.value}>
												{option.label}
												{option.value ===
													DOWNLOAD_FILES_OPTIONS.INCLUDE_ATTACHMENTS &&
													selectedAttachmentOption &&
													renderAttachmentOptions}
											</Checkbox>
										</Col>
									</Row>
								)
						)}
					</Checkbox.Group>
				</Form.Item>
			</Form>
		</Modal>
	)
}
