import { TFunction, useTranslation } from 'react-i18next'

import React, { useRef } from 'react'
import { AkaButton } from 'components'
import { Formik, FormikHelpers, FormikValues } from 'formik'
import { Input, Form } from 'antd'
import * as Yup from 'yup'
import { useAntdMessageApi } from '../../../../providers'
import { useMutation } from '@apollo/react-hooks'
import { PASSWORD_RESET_CONFIRM } from '../../../core/mutations'
import { useHistory, useParams } from 'react-router-dom'
import { ROUTES } from '../../../navigation'
import { getPasswordSchema } from '../../../../utils/validations/validationSchema'
import { cleanUpGraphQLErrorMessage } from '../../../../utils/helper.utils'

const FormItem = Form.Item

/**
 * PasswordResetConfirm is a React functional component that provides a form
 * allowing users to confirm their password reset using a unique uid and token.
 * This component integrates with GraphQL to handle the password reset confirmation
 * process, displays notifications for success and error states, and redirects users
 * upon successful completion.
 *
 * Key functionalities include:
 * - Form validation using Yup schema.
 * - Integration with GraphQL mutation to handle password reset confirmation.
 * - User feedback through notifications for success and error states.
 * - Built using Formik for form state management.
 * - Redirection to the login page upon successful password reset.
 */
export const PasswordResetConfirm = () => {
	const [t] = useTranslation()
	const history = useHistory()
	const messageApi = useAntdMessageApi()
	const resetFormRef = useRef<() => void>(() => {})
	const { uid, token } = useParams<{ uid: string; token: string }>()

	const [passwordResetConfirm] = useMutation(PASSWORD_RESET_CONFIRM, {
		onCompleted: () => {
			messageApi?.open({
				type: 'success',
				content: t('auth.passwordResetConfirmForm.messages.success')
			})
			history.push(ROUTES.login)
		},
		onError: error => {
			messageApi?.open({
				type: 'error',
				content: t('auth.passwordResetConfirmForm.messages.error', {
					message: cleanUpGraphQLErrorMessage(error.message)
				})
			})
		}
	})

	const validationSchema = (t: TFunction) =>
		Yup.object().shape({
			newPassword: getPasswordSchema(),

			confirmPassword: Yup.string()
				.oneOf(
					[Yup.ref('newPassword'), null],
					t('account.changePasswordForm.validation.notMatchingPasswords')
				)
				.required(t('account.changePasswordForm.validation.confirmNewPassword'))
		})

	return (
		<Formik
			validationSchema={validationSchema(t)}
			initialValues={{
				newPassword: '',
				confirmPassword: '',
				uid: uid,
				token: token
			}}
			onSubmit={async (
				values: FormikValues,
				{ resetForm }: FormikHelpers<FormikValues> & { resetForm: () => void }
			) => {
				resetFormRef.current = resetForm
				await passwordResetConfirm({
					variables: values
				})
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit
			}) => (
				<Form
					layout="vertical"
					data-testid="password-reset-confirm-form"
					style={{ fontFamily: 'SegoeUI, sans-serif' }}
				>
					<FormItem
						hasFeedback={true}
						validateStatus={
							touched.newPassword
								? errors.newPassword
									? 'error'
									: 'success'
								: undefined
						}
						help={
							touched.newPassword && typeof errors.newPassword === 'string'
								? errors.newPassword
								: undefined
						}
					>
						<Input.Password
							name="newPassword"
							value={values.newPassword}
							onChange={handleChange}
							onBlur={handleBlur}
							data-testid="change-password-newPassword"
							placeholder={t('account.changePasswordForm.newPassword')}
						/>
					</FormItem>
					<FormItem
						hasFeedback={true}
						validateStatus={
							touched.confirmPassword
								? errors.confirmPassword
									? 'error'
									: 'success'
								: undefined
						}
						help={
							touched.confirmPassword &&
							typeof errors.confirmPassword === 'string'
								? errors.confirmPassword
								: undefined
						}
					>
						<Input.Password
							name="confirmPassword"
							value={values.confirmPassword}
							onChange={handleChange}
							onBlur={handleBlur}
							data-testid="change-password-confirmPassword"
							placeholder={t('account.changePasswordForm.confirmPassword')}
						/>
					</FormItem>
					<FormItem data-testid="change-password-test-button">
						<AkaButton
							data-testid="change-password-submit-button"
							type="primary"
							htmlType="submit"
							onClick={() => handleSubmit()}
						>
							{t('account.changePasswordForm.save')}
						</AkaButton>
					</FormItem>
				</Form>
			)}
		</Formik>
	)
}
