import React from 'react'
import PropTypes from 'prop-types'
import { useUser } from 'providers'
import { Route, Redirect } from 'react-router-dom'
import { LoadingSpinner } from 'components'
import { ROUTES } from './routes'

/**
 * A higher-order component that acts as a private route within a React Router setup.
 *
 * @param {Object} props - The properties to customize the PrivateRoute component.
 * @param {React.Component} props.component - The component to render if the user is authenticated.
 * @returns {JSX.Element} The rendered route.
 *
 * This function checks whether the user is authenticated and if no authentication or verification is in progress.
 * If the user is authenticated, it renders the specified component.
 * If verification is in progress and the user has a token, it renders a full-page loading spinner.
 * Otherwise, it redirects the user to the login page.
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
	const { loadingVerify, loadingAuth, hasToken, isAuthenticated } = useUser()

	return (
		<Route
			{...rest}
			render={props =>
				isAuthenticated && !loadingAuth && !loadingVerify ? (
					<Component {...props} />
				) : loadingVerify && hasToken ? (
					<LoadingSpinner fullPage />
				) : (
					<Redirect
						to={{
							pathname: ROUTES.login
						}}
					/>
				)
			}
		/>
	)
}

PrivateRoute.propTypes = {
	component: PropTypes.func
}

export default PrivateRoute
