import * as React from 'react'
import PageLayout from '../../layout/containers/PageLayout'
import { useTranslation } from 'react-i18next'
import { useAntdMessageApi } from '../../../providers'
import { useEffect } from 'react'
import { Layout } from 'antd'
import { PrivacyPolicy } from '../components/PrivacyPolicy'

const { Content } = Layout

export const AcceptPrivacyPolicyPage = () => {
	const [t] = useTranslation()
	const messageApi = useAntdMessageApi()

	const mainContent = (
		<Content
			style={{
				padding: '24px 24px 24px 48px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center'
			}}
		>
			<PrivacyPolicy />
		</Content>
	)

	useEffect(() => {
		messageApi?.open({
			type: 'warning',
			content: t('core.privacyPolicy.explanation')
		})
	}, [])

	return <PageLayout renderMainContent={mainContent} />
}
