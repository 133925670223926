import * as React from 'react'
import { AkaTypography } from '../../../components'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { LATEST_PRIVACY_POLICY } from '../queries'
import { Markdown } from '../../../components/markdown/Markdown'
import AkaButton from '../../../components/button'
import { StylesDictionary } from '../../../components/typography/styles'
import { useAntdMessageApi, useUser } from '../../../providers'
import { ACCEPT_PRIVACY_POLICY } from '../mutations'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../navigation'
import { cleanUpGraphQLErrorMessage } from '../../../utils/helper.utils'

const styles: StylesDictionary = {
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		gap: 10,
		paddingTop: 18
	}
}

export const PrivacyPolicy = () => {
	const [t] = useTranslation()
	const history = useHistory()
	const messageApi = useAntdMessageApi()

	const { user, logout } = useUser()

	const latestPrivacyPolicyResponse = useQuery(LATEST_PRIVACY_POLICY, {
		fetchPolicy: 'network-only'
	})

	const latestPrivacyPolicy =
		latestPrivacyPolicyResponse.data?.latestPrivacyPolicy

	const [acceptPrivacyPolicy] = useMutation(ACCEPT_PRIVACY_POLICY, {
		onCompleted: () => {
			messageApi?.open({
				type: 'success',
				content: t('core.privacyPolicy.acceptSuccess')
			})
			user.hasAcceptedLatestPrivacyPolicy = true
			history.push(ROUTES.home)
		},
		onError: error => {
			messageApi?.open({
				type: 'error',
				content: t('core.privacyPolicy.acceptError', {
					message: cleanUpGraphQLErrorMessage(error.message)
				})
			})
		}
	})

	const handleAccept = async () => {
		await acceptPrivacyPolicy({
			variables: { privacyPolicyId: latestPrivacyPolicy.id }
		})
	}

	return (
		<>
			{latestPrivacyPolicy && (
				<>
					<AkaTypography variant="h1">
						{t('core.privacyPolicy.title')} (Stand{' '}
						{latestPrivacyPolicy.parsedCreatedAt})
					</AkaTypography>
					<Markdown>{latestPrivacyPolicy.text}</Markdown>
					<div style={styles.buttonWrapper}>
						<AkaButton
							type="primary"
							data-testid="accept-button"
							onClick={handleAccept}
						>
							{t('core.privacyPolicy.accept')}
						</AkaButton>
						<AkaButton data-testid="decline-button" onClick={logout}>
							{t('core.privacyPolicy.decline')}
						</AkaButton>
					</div>
				</>
			)}
		</>
	)
}
