import React, { useMemo, memo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Tooltip, Menu, Button, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useVT } from 'virtualizedtableforantd4'
import {
	HEADER_HEIGHT,
	CHANGED_REQUIREMENT_STATUS,
	REQUIREMENT_PRIORITY
} from 'modules/core/constants'

import { groupIdsAreEqual } from 'utils/helper.utils'
import { ROUTES } from 'modules/navigation'
import ChangedStatusBadge from './ChangedStatusBadge'

import { AkaButton, AkaTypography } from 'components'
import {
	StyledSelectRequirementsTable,
	ChapterCell,
	RequirementCell,
	CenterCell,
	ButtonRightWrapper,
	FilterMenuButtonWrapper,
	FilterMenuHeader,
	CustomFilterIcon,
	PriorityTag,
	RequirementLink
} from './styles'

const TableScrollY = `calc(100vh - (48px + ${HEADER_HEIGHT}px + 48px + 170px)`

const PRIORITY_VALUES_BY_CODE = {
	1: REQUIREMENT_PRIORITY.REQUIRED,
	2: REQUIREMENT_PRIORITY.OPTIONAL,
	3: REQUIREMENT_PRIORITY.CONDITIONAL
}
const PRIORITY_TAGS = {
	[REQUIREMENT_PRIORITY.REQUIRED]: {
		tagColor: 'red',
		tagLabel: 'P'
	},

	[REQUIREMENT_PRIORITY.OPTIONAL]: {
		tagColor: 'green',
		tagLabel: 'O'
	},
	[REQUIREMENT_PRIORITY.CONDITIONAL]: {
		tagColor: 'orange',
		tagLabel: 'K'
	}
}

const isFilterEqual = (s1, s2) => {
	if (s1 === s2) return true
	if (s1 === null || s2 === null) return false
	if (s1.length !== s2.length) return false
	for (let i = 0; i < s1.length; i++) if (s1[i] != s2[i]) return false

	return true
}

const createTableData = (chapters, changes, filters) => {
	let tableData = []
	for (const chapter of chapters) {
		const chapterRows = getChapterRows(chapter, changes, filters)
		if (chapterRows) tableData.push(chapterRows)
	}
	return tableData
}

const getRequirementRows = (chapter, changes, filters) => {
	const matchedRequirements = chapter.matrixRequirements.filter(
		requirement => requirement.contractRegionGroupIds.length
	)
	const filteredRequirementsByChangeStatus =
		filters.changeStatus || filters.priority
			? matchedRequirements.filter(requirement => {
					const isNewRequirement =
						changes.newRequirementIds.indexOf(requirement.id) >= 0
					const isChangedRequirement =
						changes.changedRequirementIds.indexOf(requirement.id) >= 0
					const isStatusChangeFilterAppliedOnRequirement =
						filters.changeStatus && isNewRequirement
							? filters.changeStatus?.includes(CHANGED_REQUIREMENT_STATUS.NEW)
							: isChangedRequirement
							? filters.changeStatus?.includes(
									CHANGED_REQUIREMENT_STATUS.CHANGED
							  )
							: false

					const isPriorityFilterAppliedOnRequirement =
						filters.priority?.includes(
							PRIORITY_VALUES_BY_CODE[requirement.priority]
						)

					if (filters.changeStatus && filters.priority) {
						return (
							isPriorityFilterAppliedOnRequirement &&
							isStatusChangeFilterAppliedOnRequirement
						)
					}
					if (filters.changeStatus) {
						return isStatusChangeFilterAppliedOnRequirement
					}
					if (filters.priority) {
						return isPriorityFilterAppliedOnRequirement
					}

					return true
			  })
			: matchedRequirements
	return filteredRequirementsByChangeStatus.length
		? filteredRequirementsByChangeStatus.map(requirement => ({
				key: requirement.id,
				requirement: {
					prefixedCaliberId: requirement.prefixedCaliberId,
					title: requirement.title,
					requirementId: requirement.id,
					chapterId: chapter.id,
					priority: PRIORITY_VALUES_BY_CODE[requirement.priority],
					contractRegionGroupIds: requirement.contractRegionGroupIds,
					changeStatus:
						changes.newRequirementIds.indexOf(requirement.id) >= 0
							? CHANGED_REQUIREMENT_STATUS.NEW
							: changes.changedRequirementIds.indexOf(requirement.id) >= 0
							? CHANGED_REQUIREMENT_STATUS.CHANGED
							: null
				}
		  }))
		: null
}

const getChapterRows = (chapter, changes, changeStatusFilter) => {
	if (chapter.chapters.length) {
		let nestedChapters = []
		const requirementRows =
			getRequirementRows(chapter, changes, changeStatusFilter) || []

		chapter.chapters.forEach(subChapter => {
			const nestedChapterRows = getChapterRows(
				subChapter,
				changes,
				changeStatusFilter
			)
			if (nestedChapterRows) {
				nestedChapters.push(nestedChapterRows)
			}
		})
		return nestedChapters.length
			? {
					key: `chapter-id:${chapter.id}`,
					chapter: chapter.fullTitle,
					children: [...requirementRows, ...nestedChapters]
			  }
			: null
	}

	if (chapter.matrixRequirements.length) {
		const requirementRows = getRequirementRows(
			chapter,
			changes,
			changeStatusFilter
		)

		return requirementRows
			? {
					key: `chapter-id:${chapter.id}`,
					chapter: chapter.fullTitle,
					children: requirementRows
			  }
			: null
	}

	return null
}

const SelectRequirementsTable = ({
	selectedContractRegionGroups,
	selectedContractRegionGroupRequirements,
	chapters,
	onSubmit,
	isSubmitting,
	isSubmitted,
	changes,
	releaseId,
	isReadOnly
}) => {
	const [vt] = useVT(() => ({ scroll: { y: TableScrollY } }), [])
	const [t] = useTranslation()

	const initialSelectedCheckboxesState =
		selectedContractRegionGroupRequirements.reduce(
			(obj, value) => ({ ...obj, [value.id]: value.contractRegionGroupIds }),
			{}
		)

	const initialSelectedRowKeys = selectedContractRegionGroupRequirements.reduce(
		(result, { allSelected, id }) =>
			allSelected ? result.push(id) && result : result,
		[]
	)

	const [selectedCheckboxes, setSelectedCheckboxes] = useState(
		initialSelectedCheckboxesState
	)
	const [selectedRowKeys, setSelectedRowKeys] = useState(initialSelectedRowKeys)

	const [filters, setFilters] = useState({ changeStatus: null, priority: null })

	const columns = [
		{
			title: t('approval.report.requirementsTable.selectAllRequirements'),
			width: 500,
			fixed: 'left',
			render: (_, record) => {
				if (record.chapter) {
					return <ChapterCell>{record.chapter}</ChapterCell>
				} else {
					const linkParams = {
						pathname: `${ROUTES.catalog}/${releaseId}`,
						search: `?${new URLSearchParams({
							chapterId: record.requirement.chapterId,
							requirementId: record.requirement.prefixedCaliberId
						}).toString()}`
					}
					return (
						<RequirementCell>
							<RequirementLink to={linkParams} target="_blank">
								<div className="requirementId">
									{record.requirement.prefixedCaliberId}
								</div>
							</RequirementLink>
							<RequirementLink to={linkParams} target="_blank">
								<div>{record.requirement.title}</div>
							</RequirementLink>
						</RequirementCell>
					)
				}
			}
		},
		{
			title: t('approval.report.requirementsTable.priority.title'),
			dataIndex: 'priority',
			fixed: 'left',
			width: 25,
			filterIcon: () => (
				<Tooltip title={t('approval.report.requirementsTable.priority.filter')}>
					<CustomFilterIcon />
				</Tooltip>
			),
			filterDropdown: ({
				selectedKeys,
				setSelectedKeys,
				filters,
				clearFilters,
				confirm
			}) => {
				return (
					<>
						<FilterMenuHeader>
							<AkaTypography gutterbottom={0} variant="body" color="white">
								{t('approval.report.requirementsTable.priority.title')}
							</AkaTypography>
						</FilterMenuHeader>
						<Menu
							multiple
							onSelect={({ selectedKeys: keys }) => {
								setSelectedKeys(keys)
							}}
							onDeselect={({ selectedKeys: keys }) => {
								setSelectedKeys(keys)
							}}
							selectedKeys={selectedKeys}
							items={filters.map(filter => {
								const { tagColor, tagLabel } = PRIORITY_TAGS[filter.value]
								return {
									key: filter.value,
									label: (
										<Space>
											<Checkbox checked={selectedKeys.includes(filter.value)} />
											<PriorityTag color={tagColor}>{tagLabel}</PriorityTag>
											{filter.text}
										</Space>
									)
								}
							})}
						/>
						<FilterMenuButtonWrapper>
							<Button
								disabled={!selectedKeys.length}
								type="link"
								size="small"
								onClick={clearFilters}
							>
								{t('approval.report.requirementsTable.changedStatus.reset')}
							</Button>
							<Button size="small" onClick={() => confirm()} type="primary">
								{t('approval.report.requirementsTable.changedStatus.ok')}
							</Button>
						</FilterMenuButtonWrapper>
					</>
				)
			},
			filters: [
				{
					text: t('approval.report.requirementsTable.priority.required'),
					value: REQUIREMENT_PRIORITY.REQUIRED
				},
				{
					text: t('approval.report.requirementsTable.priority.optional'),
					value: REQUIREMENT_PRIORITY.OPTIONAL
				},
				{
					text: t('approval.report.requirementsTable.priority.conditional'),
					value: REQUIREMENT_PRIORITY.CONDITIONAL
				}
			],
			render: (_, record) => {
				if (record.requirement) {
					const { requirement } = record
					const { priority } = requirement
					const { tagColor, tagLabel } = PRIORITY_TAGS[priority]
					return (
						<CenterCell>
							<Tooltip
								placement="left"
								title={t(
									`approval.report.requirementsTable.priority.${priority}`
								)}
							>
								<PriorityTag color={tagColor}>{tagLabel}</PriorityTag>
							</Tooltip>
						</CenterCell>
					)
				}
				return null
			}
		},
		{
			title: t('approval.report.requirementsTable.changedStatus.title'),
			width: 25,
			dataIndex: 'changeStatus',
			fixed: 'left',
			filterIcon: () => (
				<Tooltip
					title={t('approval.report.requirementsTable.changedStatus.filter')}
				>
					<CustomFilterIcon />
				</Tooltip>
			),
			filterDropdown: ({
				selectedKeys,
				setSelectedKeys,
				filters,
				clearFilters,
				confirm
			}) => {
				return (
					<>
						<FilterMenuHeader>
							<AkaTypography gutterbottom={0} variant="body" color="white">
								{t('approval.report.requirementsTable.changedStatus.title')}
							</AkaTypography>
						</FilterMenuHeader>
						<Menu
							multiple
							onSelect={({ selectedKeys: keys }) => {
								setSelectedKeys(keys)
							}}
							onDeselect={({ selectedKeys: keys }) => {
								setSelectedKeys(keys)
							}}
							selectedKeys={selectedKeys}
							items={filters.map(filter => ({
								key: filter.value,
								label: (
									<Space>
										<Checkbox checked={selectedKeys.includes(filter.value)} />
										<ChangedStatusBadge
											showTooltip={false}
											status={filter.value}
										/>
										{filter.text}
									</Space>
								)
							}))}
						/>
						<FilterMenuButtonWrapper>
							<Button
								disabled={!selectedKeys.length}
								type="link"
								size="small"
								onClick={clearFilters}
							>
								{t('approval.report.requirementsTable.changedStatus.reset')}
							</Button>
							<Button size="small" onClick={() => confirm()} type="primary">
								{t('approval.report.requirementsTable.changedStatus.ok')}
							</Button>
						</FilterMenuButtonWrapper>
					</>
				)
			},
			render: (_, record) => {
				if (record.requirement) {
					const { requirement } = record

					if (requirement.changeStatus) {
						return (
							<CenterCell>
								<ChangedStatusBadge status={requirement.changeStatus} />
							</CenterCell>
						)
					}
				}
				return null
			},
			filters: [
				{
					text: t('approval.report.requirementsTable.changedStatus.new'),
					value: CHANGED_REQUIREMENT_STATUS.NEW
				},
				{
					text: t('approval.report.requirementsTable.changedStatus.changed'),
					value: CHANGED_REQUIREMENT_STATUS.CHANGED
				}
			]
		},
		...selectedContractRegionGroups.map(contractRegionGroup => ({
			title: (
				<div>
					<Tooltip
						placement="bottom"
						title={contractRegionGroup.regions
							.map(region => region.name)
							.join(', ')}
					>
						{contractRegionGroup.contract.contractId}
					</Tooltip>
				</div>
			),
			width: 25,
			render: (_, record) => {
				if (record?.requirement) {
					const { requirement } = record
					return (
						requirement.contractRegionGroupIds.indexOf(
							contractRegionGroup.id
						) >= 0 && (
							<Checkbox
								disabled={isReadOnly}
								checked={
									selectedCheckboxes[requirement.requirementId] &&
									selectedCheckboxes[requirement.requirementId].indexOf(
										contractRegionGroup.id
									) >= 0
								}
								onChange={e =>
									onCheckboxChange(
										e,
										requirement.requirementId,
										contractRegionGroup.id,
										requirement.contractRegionGroupIds
									)
								}
							/>
						)
					)
				}
			}
		}))
	]

	const onCheckboxChange = useCallback(
		(e, requirementId, contractRegionGroupId, contractRegionGroupIds) => {
			if (e.target.checked) {
				const newContractRegionGroupIds = selectedCheckboxes[requirementId]
					? [...selectedCheckboxes[requirementId], contractRegionGroupId]
					: [contractRegionGroupId]
				setSelectedCheckboxes(state => {
					return {
						...state,
						[requirementId]: newContractRegionGroupIds
					}
				})
				if (
					groupIdsAreEqual(newContractRegionGroupIds, contractRegionGroupIds)
				) {
					setSelectedRowKeys(state => [...state, requirementId])
				}
			} else {
				setSelectedRowKeys(state =>
					state.filter(rowKey => rowKey !== requirementId)
				)
				setSelectedCheckboxes(state => ({
					...state,
					[requirementId]: state[requirementId].filter(
						id => id !== contractRegionGroupId
					)
				}))
			}
		},
		[selectedCheckboxes]
	)

	const tableData = useMemo(() => {
		return createTableData(chapters, changes, filters)
	}, [chapters, changes, filters])

	const rowSelection = {
		onChange: (newSelectedRowKeys, selectedRows) => {
			const newRequirementRowKeys = newSelectedRowKeys.filter(
				key => !key.startsWith('chapter-id:')
			)
			const currentVisibleSelectedRequirementIds = selectedRowKeys.filter(key =>
				JSON.stringify(tableData).includes(`"requirementId":"${key}"`)
			)
			const addKeys = newRequirementRowKeys.filter(
				key => !selectedRowKeys.includes(key)
			)
			const removeKeys = currentVisibleSelectedRequirementIds.filter(key => {
				return !newRequirementRowKeys.includes(key)
			})

			const checkedCheckboxRows = selectedRows
				.filter(row => addKeys.find(key => row.key === key))
				.reduce(
					(rows, row) => ({
						...rows,
						[row.key]: row.requirement.contractRegionGroupIds
					}),
					{}
				)

			setSelectedCheckboxes(state => {
				const stateCopy = { ...state }
				removeKeys.forEach(key => {
					delete stateCopy[key]
				})
				return {
					...stateCopy,
					...checkedCheckboxRows
				}
			})
			setSelectedRowKeys(selectedKeys => [
				...selectedKeys.filter(key => removeKeys.indexOf(key) < 0),
				...addKeys
			])
		},
		getCheckboxProps: () => {
			return {
				disabled: isReadOnly
			}
		},
		selectedRowKeys: selectedRowKeys
	}

	const handleSubmit = () => {
		const selectedContractRegionGroupRequirements = Object.entries(
			selectedCheckboxes
		).map(([requirementId, contractRegionGroupIds]) => ({
			requirementId,
			contractRegionGroupIds
		}))
		onSubmit(selectedContractRegionGroupRequirements)
	}

	return (
		<>
			<StyledSelectRequirementsTable
				onChange={(_, newFilters) => {
					if (!isFilterEqual(newFilters.changeStatus, filters.changeStatus)) {
						setFilters(filters => ({
							...filters,
							changeStatus: newFilters.changeStatus
						}))
					}
					if (!isFilterEqual(newFilters.priority, filters.priority)) {
						setFilters(filters => ({
							...filters,
							priority: newFilters.priority
						}))
					}
				}}
				columns={columns}
				rowSelection={{ ...rowSelection, checkStrictly: false }}
				dataSource={tableData}
				pagination={false}
				components={vt}
				size="small"
				scroll={{ y: TableScrollY }}
				expandable={{
					defaultExpandAllRows: true,
					indentSize: 3
				}}
			/>
			<ButtonRightWrapper>
				{!isReadOnly && (
					<AkaButton
						key="submit"
						type="primary"
						loading={isSubmitting}
						onClick={handleSubmit}
						disabled={isSubmitting}
					>
						{t(t('approval.report.requirementsTable.submitButton'))}
					</AkaButton>
				)}
				{isSubmitted && (
					<AkaTypography variant={'tiny'}>
						{t('approval.report.reportAlreadySubmitted')}
					</AkaTypography>
				)}
			</ButtonRightWrapper>
		</>
	)
}

SelectRequirementsTable.propTypes = {
	selectedContractRegionGroups: PropTypes.array,
	chapters: PropTypes.array,
	changes: PropTypes.shape({
		changedRequirementIds: PropTypes.arrayOf(PropTypes.string),
		newRequirementIds: PropTypes.arrayOf(PropTypes.string)
	}),
	selectedContractRegionGroupRequirements: PropTypes.array,
	onSubmit: PropTypes.func,
	isSubmitting: PropTypes.bool,
	isSubmited: PropTypes.bool,
	releaseId: PropTypes.string,
	isReadOnly: PropTypes.bool
}

SelectRequirementsTable.defaultProps = {
	selectedContractRegionGroups: [],
	chapters: [],
	changes: null,
	selectedContractRegionGroupRequirements: [],
	onSubmit: () => {},
	isSubmitting: false,
	isReadOnly: false,
	isSubmitted: false
}

export default memo(SelectRequirementsTable)
