import React, { useCallback, useContext, useState } from 'react'
import * as PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import { SEND_RELEASE_PACKET_EMAIL } from 'modules/core/mutations'
import { DOWNLOAD_FILES_OPTIONS } from 'modules/core/constants'
import { CustomCloudDownloadOutlined } from './styles'
import { ContractsFilterContext } from './CatalogPage'
import CloseButton from './CloseButton'
import { useAntdMessageApi } from 'providers'
import { DownloadDialog } from '../components/DownloadDialog'

const CatalogDownLoad = ({ selectedReleaseToCompareId, release }) => {
	const [t] = useTranslation()
	const [open, setOpen] = useState(false)
	const openModal = useCallback(() => setOpen(true), [])
	const closeModal = useCallback(() => setOpen(false), [])
	const messageApi = useAntdMessageApi()
	const { selectedContractIdsList: selectedContractIds, releaseContracts } =
		useContext(ContractsFilterContext)
	const [sendReleasePacketEmail, { loading: sending }] = useMutation(
		SEND_RELEASE_PACKET_EMAIL,
		{
			onCompleted: () => {
				messageApi?.open({
					type: 'success',
					content: t('catalog.message.successSendingEmail')
				})
			},
			onError: () => {
				messageApi?.open({
					type: 'error',
					content: t('catalog.message.errorSendingEmail')
				})
			}
		}
	)

	const handleSubmit = useCallback(
		(checkedList, selectedAttachmentOption) => {
			sendReleasePacketEmail({
				variables: {
					releaseId: release.id,
					selectedContracts: selectedContractIds,
					isFiltered: releaseContracts.length !== selectedContractIds.length,
					comparedReleaseId: selectedReleaseToCompareId,
					includeSvdXmlFiles:
						checkedList.includes(
							DOWNLOAD_FILES_OPTIONS.INCLUDE_SVD_XML_FILES
						) || undefined,
					includeSvdPdfFiles:
						checkedList.includes(
							DOWNLOAD_FILES_OPTIONS.INCLUDE_SVD_PDF_FILES
						) || undefined,
					includeInspectionDocumentXmlFiles:
						checkedList.includes(
							DOWNLOAD_FILES_OPTIONS.INCLUDE_INSPECTION_DOCUMENT_XML_FILES
						) || undefined,
					includeInspectionDocumentCsvFiles:
						checkedList.includes(
							DOWNLOAD_FILES_OPTIONS.INCLUDE_INSPECTION_DOCUMENT_CSV_FILES
						) || undefined,
					includeAkaPdf:
						checkedList.includes(DOWNLOAD_FILES_OPTIONS.INCLUDE_AKA_PDF) ||
						undefined,
					includeBaseFile:
						checkedList.includes(DOWNLOAD_FILES_OPTIONS.INCLUDE_BASE_FILE) ||
						undefined,
					includeHelpFiles:
						checkedList.includes(DOWNLOAD_FILES_OPTIONS.INCLUDE_HELP_FILES) ||
						undefined,
					attachmentOption: checkedList.includes(
						DOWNLOAD_FILES_OPTIONS.INCLUDE_ATTACHMENTS
					)
						? selectedAttachmentOption
						: undefined,
					includePcxFiles:
						checkedList.includes(DOWNLOAD_FILES_OPTIONS.INCLUDE_PCX_FILES) ||
						false,
					includeRevisionHistoryFile:
						selectedReleaseToCompareId &&
						checkedList.includes(
							DOWNLOAD_FILES_OPTIONS.INCLUDE_REVISION_HISTORY_FILE
						)
				}
			}).then(() => closeModal())
		},
		[
			sendReleasePacketEmail,
			release.id,
			selectedContractIds,
			releaseContracts,
			selectedReleaseToCompareId,
			closeModal
		]
	)

	return (
		<>
			<CloseButton />
			<CustomCloudDownloadOutlined
				onClick={openModal}
				data-testid="download-image"
			/>
			{open && (
				<DownloadDialog
					release={release}
					handleSubmit={handleSubmit}
					handleClose={closeModal}
					sending={sending}
					open={open}
				/>
			)}
		</>
	)
}

CatalogDownLoad.propTypes = {
	release: PropTypes.shape({}).isRequired,
	selectedReleaseToCompareId: PropTypes.string
}

CatalogDownLoad.defaultProps = {
	selectedReleaseToCompareId: undefined
}

export default CatalogDownLoad
