import React from 'react'

import { Layout } from 'antd'
import logo from 'img/AKA_portal_logo.svg'
import { PasswordResetRequest } from '../components/PasswordResetRequest'
import { AkaTypography } from '../../../components'
import {
	AuthWrapper,
	Headline,
	StyledContent,
	StyledHeader,
	StyledLink
} from '../components/styles'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../navigation'

/**
 * PasswordResetRequestPage Component
 *
 * This component renders the password reset request page.
 * It includes:
 * - A layout wrapper
 * - A header with the company logo
 * - Content including a headline and the password reset request form
 * - A link to navigate back to the login page
 *
 * It uses:
 * - `useTranslation` for localizing the text
 * - Routing to link back to the login page
 */
const PasswordResetRequestPage = () => {
	const [t] = useTranslation()

	return (
		<Layout>
			<StyledHeader>
				<img src={logo} width={300} alt="logo" />
			</StyledHeader>

			<StyledContent>
				<AuthWrapper>
					<Headline>
						<AkaTypography variant="bodySegoe">
							{t('auth.passwordRequestPage.headline')}
						</AkaTypography>
					</Headline>
					<PasswordResetRequest />
				</AuthWrapper>
				<StyledLink to={ROUTES.login}>
					<AkaTypography
						gutterbottom={0}
						variant={'bodySegoe'}
						color={'primary'}
					>
						{t('auth.backToLogin')}
					</AkaTypography>
				</StyledLink>
			</StyledContent>
		</Layout>
	)
}

export default PasswordResetRequestPage
