export default {
	generalError:
		'Hups, hier ist etwas schief gelaufen. Versuchen Sie bitte erneut.',
	pageNotFound: 'Diese Seite konnte nicht gefunden werden.',
	notFoundError: 'Objekt nicht in der Datenbank gefunden',
	search: 'Suchen',
	downloadReleaseRequirementChanges:
		'Wir bereiten den Vergleich für Sie vor. Dies kann bis zu 30 Sekunden dauern.',
	justificationForSatisfiedRequirement:
		'Erhaltene Begründung für ungültige Anforderung (entweder ist der Vertrag nicht ausgewählt oder die Anforderung ist erfüllt)',
	missingSoftwareSystemData:
		'Es fehlen Pflichtangaben im Bereich "Angaben zur Vertragssoftware". ' +
		'Bitte vervollständigen Sie Ihre Angaben.',
	privacyPolicy: {
		title: 'Datenschutzerklärung',
		explanation:
			'Bitte akzeptieren Sie die aktuellste Datenschutzerklärung um fortfahren ' +
			'zu können.',
		accept: 'Akzeptieren',
		decline: 'Ablehnen',
		acceptSuccess: 'Datenschutzerklärung erfolgreich akzeptiert.',
		acceptError:
			'Beim Akzeptieren der Datenschutzerklärung ist etwas schief gelaufen!'
	}
}
