import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Layout } from 'antd'
const { Header, Content } = Layout

export const Headline = styled.div`
	text-align: center;
	margin-bottom: 30px;
`

export const AuthWrapper = styled.div`
	margin-top: 60px;
	margin-bottom: 54px;
`

export const StyledLink = styled(Link)`
	text-decoration: underline;
	&:hover {
		text-decoration: underline;
	}
`
export const StyledATag = styled.a`
	text-decoration: underline;
	&:hover {
		text-decoration: underline;
	}
`

export const StyledHeader = styled(Header)`
	width: 100%;
	padding-bottom: 32px;
	height: 290px;
	min-height: 120px;
	display: flex;
	justify-content: center;

	> img {
		align-self: flex-end;
	}
`

export const StyledContent = styled(Content)`
	flex-direction: column;
	display: flex;
	max-width: 300px;
	margin: auto;
	align-items: center;
`
