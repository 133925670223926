import type { FC } from 'react'
import React from 'react'
import type { ReactMarkdownOptions } from 'react-markdown'
import ReactMarkdown from 'react-markdown'

import { Typography } from 'antd'

/**
 * A functional component that renders Markdown content.
 * This component customizes how certain Markdown elements are rendered
 * by providing specific components for each supported Markdown element.
 *
 * The component is configured to render the following Markdown elements with custom components:
 * - h1: Rendered with MarkdownH1Tag
 * - h2: Rendered with MarkdownH2Tag
 * - h3: Rendered with MarkdownH3Tag
 * - h4: Rendered with MarkdownH4Tag
 * - li: Rendered with MarkdownLiTag
 * - ol: Rendered with MarkdownOlTag
 * - p: Rendered with MarkdownPTag
 * - ul: Rendered with MarkdownUlTag
 */
export const Markdown: FC<
	ReactMarkdownOptions & { children: React.ReactNode }
> = ({ children, ...props }) => {
	const MarkdownPTag: FC<{ children: React.ReactNode }> = ({
		children,
		...props
	}) => <Typography {...props}>{children}</Typography>

	const MarkdownH1Tag: FC<{ children: React.ReactNode }> = ({
		children,
		...props
	}) => (
		<Typography.Title level={1} {...props}>
			{children}
		</Typography.Title>
	)

	const MarkdownH2Tag: FC<{ children: React.ReactNode }> = ({
		children,
		...props
	}) => (
		<Typography.Title level={2} {...props}>
			{children}
		</Typography.Title>
	)

	const MarkdownH3Tag: FC<{ children: React.ReactNode }> = ({
		children,
		...props
	}) => (
		<Typography.Title level={3} {...props}>
			{children}
		</Typography.Title>
	)

	const MarkdownH4Tag: FC<{ children: React.ReactNode }> = ({
		children,
		...props
	}) => (
		<Typography.Title level={4} {...props}>
			{children}
		</Typography.Title>
	)

	const MarkdownUlTag: FC<{ children: React.ReactNode }> = ({
		children,
		...props
	}) => <ul {...props}>{children}</ul>

	const MarkdownOlTag: FC<{ children: React.ReactNode }> = ({
		children,
		...props
	}) => <ol {...props}>{children}</ol>

	const MarkdownLiTag: FC<{ children: React.ReactNode }> = ({
		children,
		...props
	}) => (
		<li>
			<Typography {...props}>{children}</Typography>
		</li>
	)

	const markdownComponentConfiguration = {
		h1: MarkdownH1Tag,
		h2: MarkdownH2Tag,
		h3: MarkdownH3Tag,
		h4: MarkdownH4Tag,
		li: MarkdownLiTag,
		ol: MarkdownOlTag,
		p: MarkdownPTag,
		ul: MarkdownUlTag
	}

	return (
		<ReactMarkdown components={markdownComponentConfiguration} {...props}>
			{children}
		</ReactMarkdown>
	)
}
