import { TFunction, useTranslation } from 'react-i18next'
import React, { useRef } from 'react'
import { AkaButton } from 'components'
import { Formik, FormikHelpers, FormikValues } from 'formik'
import { Input, Form } from 'antd'
import * as Yup from 'yup'
import { useAntdMessageApi } from '../../../../providers'
import { useMutation } from '@apollo/react-hooks'
import { PASSWORD_RESET_REQUEST } from '../../../core/mutations'

const FormItem = Form.Item

/**
 * PasswordResetRequest is a component that allows users to request a password reset.
 *
 * The component leverages Formik for form handling and Yup for validation.
 * It uses GraphQL mutations to send the password reset request.
 *
 * When the request is successfully completed, a success message is shown.
 * If there is an error in the request, an error message is displayed.
 *
 * The form consists of an email input field, which is validated for proper email format
 * and checked to be a required field. Upon submission, the form is reset and the request
 * is sent with the entered values.
 *
 * The use of translation hooks allows the component to support multiple languages,
 * providing localized validation messages and placeholders.
 *
 * Form Layout:
 * - Email input field with validation and feedback.
 * - Submit button to trigger the password reset request.
 */
export const PasswordResetRequest = () => {
	const [t] = useTranslation()
	const messageApi = useAntdMessageApi()
	const resetFormRef = useRef<() => void>(() => {})

	const [resetPasswordRequest] = useMutation(PASSWORD_RESET_REQUEST, {
		onCompleted: () => {
			messageApi?.open({
				type: 'success',
				content: t('auth.passwordResetRequestForm.messages.success')
			})
			if (resetFormRef.current) {
				resetFormRef.current()
			}
		},
		onError: () => {
			messageApi?.open({
				type: 'error',
				content: t('auth.passwordResetRequestForm.messages.error')
			})
		}
	})

	const validationSchema = (t: TFunction) =>
		Yup.object().shape({
			email: Yup.string()
				.email(t('auth.validations.email'))
				.required(t('auth.emailRequired'))
		})

	return (
		<Formik
			validationSchema={validationSchema(t)}
			initialValues={{
				email: ''
			}}
			onSubmit={async (
				values: FormikValues,
				{ resetForm }: FormikHelpers<FormikValues> & { resetForm: () => void }
			) => {
				resetFormRef.current = resetForm
				resetPasswordRequest({
					variables: values
				})
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit
			}) => (
				<Form
					layout="vertical"
					data-testid="password-reset-form"
					style={{ fontFamily: 'SegoeUI, sans-serif' }}
				>
					<FormItem
						hasFeedback={true}
						validateStatus={
							touched.email ? (errors.email ? 'error' : undefined) : undefined
						}
						help={
							touched.email && typeof errors.email === 'string'
								? errors.email
								: undefined
						}
					>
						<Input
							name="email"
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
							data-testid="password-reset-email"
							placeholder={t('auth.email')}
						/>
					</FormItem>

					<FormItem data-testid="password-reset-test-button">
						<AkaButton
							data-testid="password-reset-submit-button"
							type="primary"
							htmlType="submit"
							onClick={() => handleSubmit()}
						>
							{t('auth.passwordForget')}
						</AkaButton>
					</FormItem>
				</Form>
			)}
		</Formik>
	)
}
