import * as Yup from 'yup'
import {
	AUTH_PASSWORD_MIN_LENGTH,
	AUTH_PASSWORD_SPECIAL_CHARACTERS
} from '../../modules/core/constants'
import { escapeRegExp } from '../helper.utils'
import { t } from 'i18next'

/**
 * Validates a password based on specific criteria using Yup.
 *
 * The password schema requires:
 * - The password to be provided (non-empty)
 * - Minimum length specified by AUTH_PASSWORD_MIN_LENGTH
 * - At least one lowercase letter
 * - At least one uppercase letter
 * - At least one numeric digit
 * - At least one special character from AUTH_PASSWORD_SPECIAL_CHARACTERS
 *
 * Localized validation error messages are provided via the `t` function.
 *
 * @returns {Yup.StringSchema} The Yup validation schema for the password.
 */
export const getPasswordSchema = (): Yup.StringSchema =>
	Yup.string()
		.required(t('auth.passwordRequired'))
		.min(
			AUTH_PASSWORD_MIN_LENGTH,
			t('account.changePasswordForm.validation.passwordMinLength', {
				minLength: AUTH_PASSWORD_MIN_LENGTH
			})
		)
		.matches(
			/[a-z]/,
			t('account.changePasswordForm.validation.passwordLowerCase')
		)
		.matches(
			/[A-Z]/,
			t('account.changePasswordForm.validation.passwordUpperCase')
		)
		.matches(/\d/, t('account.changePasswordForm.validation.passwordNumber'))
		.matches(
			new RegExp(`[${escapeRegExp(AUTH_PASSWORD_SPECIAL_CHARACTERS)}]`),
			t('account.changePasswordForm.validation.passwordSpecialCharacters', {
				specialCharacters: AUTH_PASSWORD_SPECIAL_CHARACTERS
			})
		)
