import { TFunction, useTranslation } from 'react-i18next'
import React, { useRef } from 'react'
import { AkaButton } from 'components'
import { Formik, FormikHelpers, FormikValues } from 'formik'
import { Input, Form } from 'antd'
import * as Yup from 'yup'
import { useAntdMessageApi, useUser } from '../../../../providers'
import { useMutation } from '@apollo/react-hooks'
import { CHANGE_PASSWORD } from '../../../core/mutations'
import { getPasswordSchema } from '../../../../utils/validations/validationSchema'
import { cleanUpGraphQLErrorMessage } from '../../../../utils/helper.utils'
const FormItem = Form.Item

export const ChangePassword = () => {
	const userContext = useUser()
	const [t] = useTranslation()
	const messageApi = useAntdMessageApi()
	const resetFormRef = useRef<() => void>(() => {})

	const [changePassword] = useMutation(CHANGE_PASSWORD, {
		onCompleted: () => {
			messageApi?.open({
				type: 'success',
				content: t('account.changePasswordForm.messages.success')
			})
			if (resetFormRef.current) {
				resetFormRef.current()
			}
			// update forcePasswordChange in the frontend application to save an additional request.
			userContext.user.forcePasswordChange = false
		},
		onError: error => {
			messageApi?.open({
				type: 'error',
				content: t('account.changePasswordForm.messages.error', {
					message: cleanUpGraphQLErrorMessage(error.message)
				})
			})
		}
	})

	const validationSchema = (t: TFunction) =>
		Yup.object().shape({
			currentPassword: Yup.string().required(t('auth.passwordRequired')),
			newPassword: getPasswordSchema(),
			confirmPassword: Yup.string()
				.oneOf(
					[Yup.ref('newPassword'), null],
					t('account.changePasswordForm.validation.notMatchingPasswords')
				)
				.required(t('account.changePasswordForm.validation.confirmNewPassword'))
		})

	return (
		<Formik
			validationSchema={validationSchema(t)}
			initialValues={{
				currentPassword: '',
				newPassword: '',
				confirmPassword: ''
			}}
			onSubmit={async (
				values: FormikValues,
				{ resetForm }: FormikHelpers<FormikValues> & { resetForm: () => void }
			) => {
				resetFormRef.current = resetForm
				await changePassword({
					variables: values
				})
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit
			}) => (
				<Form
					layout="vertical"
					data-testid="change-password-form"
					style={{ fontFamily: 'SegoeUI, sans-serif' }}
				>
					<FormItem
						hasFeedback={true}
						validateStatus={
							touched.currentPassword
								? errors.currentPassword
									? 'error'
									: undefined
								: undefined
						}
						help={
							touched.currentPassword &&
							typeof errors.currentPassword === 'string'
								? errors.currentPassword
								: undefined
						}
					>
						<Input.Password
							name="currentPassword"
							value={values.currentPassword}
							onChange={handleChange}
							onBlur={handleBlur}
							data-testid="change-password-currentPassword"
							placeholder={t('account.changePasswordForm.currentPassword')}
						/>
					</FormItem>
					<FormItem
						hasFeedback={true}
						validateStatus={
							touched.newPassword
								? errors.newPassword
									? 'error'
									: 'success'
								: undefined
						}
						help={
							touched.newPassword && typeof errors.newPassword === 'string'
								? errors.newPassword
								: undefined
						}
					>
						<Input.Password
							name="newPassword"
							value={values.newPassword}
							onChange={handleChange}
							onBlur={handleBlur}
							data-testid="change-password-newPassword"
							placeholder={t('account.changePasswordForm.newPassword')}
						/>
					</FormItem>
					<FormItem
						hasFeedback={true}
						validateStatus={
							touched.confirmPassword
								? errors.confirmPassword
									? 'error'
									: 'success'
								: undefined
						}
						help={
							touched.confirmPassword &&
							typeof errors.confirmPassword === 'string'
								? errors.confirmPassword
								: undefined
						}
					>
						<Input.Password
							name="confirmPassword"
							value={values.confirmPassword}
							onChange={handleChange}
							onBlur={handleBlur}
							data-testid="change-password-confirmPassword"
							placeholder={t('account.changePasswordForm.confirmPassword')}
						/>
					</FormItem>
					<FormItem data-testid="change-password-test-button">
						<AkaButton
							data-testid="change-password-submit-button"
							type="primary"
							htmlType="submit"
							onClick={() => handleSubmit()}
						>
							{t('account.changePasswordForm.save')}
						</AkaButton>
					</FormItem>
				</Form>
			)}
		</Formik>
	)
}
