import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { LoginPage } from 'modules/auth'
import { AccountPage } from 'modules/account'
import { NotificationsPage } from 'modules/notifications'
import NotFoundPage from 'modules/core/components/NotFound'
import { CatalogPage } from 'modules/catalog'
import { ROUTES } from 'modules/navigation'
import { PrivateRoute } from 'modules/navigation'
import {
	ApprovalListPage,
	SelectContractRegionsPage,
	SelectRequirementsPage,
	JustificationsPage,
	ContentCheckDetailsPage,
	SystemInfoPage,
	SystemCostsPage
} from 'modules/approval'
import ChangePasswordPage from '../account/containers/ChangePasswordPage'
import PasswordResetRequestPage from '../auth/containers/PasswordResetRequestPage'
import PasswordResetConfirmPage from '../auth/containers/PasswordResetConfirmPage'
import { AcceptPrivacyPolicyPage } from '../core/containers/AcceptPrivacyPolicyPage'

export default () => (
	<Switch>
		<PrivateRoute path={ROUTES.home} component={NotificationsPage} exact />
		<Route path={ROUTES.login} component={LoginPage} />
		<Route path={ROUTES.passwordReset} component={PasswordResetRequestPage} />
		<Route
			path={ROUTES.passwordResetConfirm}
			component={PasswordResetConfirmPage}
		/>
		<PrivateRoute path={`${ROUTES.catalog}/:id`} component={CatalogPage} />
		<PrivateRoute
			path={`${ROUTES.approval.report.systemInfo}`}
			component={SystemInfoPage}
		/>
		<PrivateRoute
			path={`${ROUTES.approval.report.systemCosts}`}
			component={SystemCostsPage}
		/>
		<PrivateRoute
			exact
			path={`${ROUTES.approval.base}/:quarter`}
			component={ApprovalListPage}
		/>
		<PrivateRoute
			path={ROUTES.approval.contentCheck.contentCheckDetails}
			component={ContentCheckDetailsPage}
		/>
		<PrivateRoute
			path={ROUTES.approval.report.selectContractRegions}
			component={SelectContractRegionsPage}
		/>
		<PrivateRoute
			path={ROUTES.approval.report.selectRequirements}
			component={SelectRequirementsPage}
		/>
		<PrivateRoute
			path={ROUTES.approval.report.justifications}
			component={JustificationsPage}
		/>
		<PrivateRoute path={ROUTES.account.base} component={AccountPage} exact />
		<PrivateRoute
			path={ROUTES.account.changePassword}
			component={ChangePasswordPage}
		/>
		<PrivateRoute
			path={ROUTES.core.acceptPrivacyPolicy}
			component={AcceptPrivacyPolicyPage}
		/>
		<Route path={ROUTES.notFound} component={NotFoundPage} exact />
	</Switch>
)
