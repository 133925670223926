import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import Login from '../components/Login'
import { useUser } from 'providers'
import { AkaTypography } from 'components'
import { Layout } from 'antd'
import logo from 'img/AKA_portal_logo.svg'
import { HEADER_HEIGHT } from 'modules/core/constants'
import { ROUTES } from '../../navigation'
import {
	StyledATag,
	StyledContent,
	StyledHeader,
	StyledLink
} from '../components/styles'

const validationSchema = t =>
	Yup.object().shape({
		email: Yup.string()
			.email(t('auth.validations.email'))
			.required(t('auth.emailRequired')),
		password: Yup.string().required(t('auth.passwordRequired'))
	})

const LoginPage = () => {
	const [t] = useTranslation()
	const {
		user,
		login,
		error,
		loadingAuth: loading,
		loadingVerify,
		isAuthenticated,
		redirect
	} = useUser()

	useEffect(() => {
		if (user && isAuthenticated && !loadingVerify && !loading) {
			redirect(ROUTES.home)
		}
	}, [user, redirect, loadingVerify, loading, isAuthenticated])

	const handleSubmit = variables => {
		login(variables)
	}

	if (user && isAuthenticated && !loadingVerify && !loading)
		return (
			<AkaTypography variant="bodySegoe2">
				You are already logged in
			</AkaTypography>
		)

	return (
		<Layout>
			<StyledHeader>
				<img src={logo} width={300} alt="logo" />
			</StyledHeader>
			<StyledContent>
				<Login
					validationSchema={validationSchema(t)}
					onSubmit={handleSubmit}
					loading={loading}
					error={error}
				/>
				<StyledATag href="mailto:aka-portal@hzv.de?subject=Neuer%20Benutzer">
					<AkaTypography variant={'bodySegoe'} color={'primary'}>
						{t('auth.requestAccount')}
					</AkaTypography>
				</StyledATag>
				<StyledLink to={ROUTES.passwordReset}>
					<AkaTypography
						gutterbottom={0}
						variant={'bodySegoe'}
						color={'primary'}
					>
						{t('auth.passwordForget')}
					</AkaTypography>
				</StyledLink>
				<AkaTypography
					gutterbottom={0}
					style={{ marginTop: HEADER_HEIGHT }}
					variant={'bodySegoe'}
				>
					{t('auth.footer.company')}
				</AkaTypography>
				<StyledATag
					href="https://www.haevg-rz.de/impressum/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<AkaTypography variant={'bodySegoe'} color={'primary'}>
						{t('auth.footer.impressum')}
					</AkaTypography>
				</StyledATag>
			</StyledContent>
		</Layout>
	)
}

export default LoginPage
