import React from 'react'
import * as PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import { AkaTypography, Card, CardSubSection, HeadSection } from 'components'
import { REQUIREMENT_PRIORITY_CODES } from 'modules/core/constants'
import {
	BookMark,
	getItemBackgroundColor,
	getItemTopBorderColor,
	SpecialRequirementWrapper
} from './styles'
import Regions from './Regions'
import { DIFF } from '../../core/constants'
import theme from 'components/themeProvider/theme'
import { Affix } from 'antd'

const getColor = (defaultColor, diff) => {
	if (diff === DIFF.NEW) return 'insContent'
	if (diff === DIFF.DELETED) return 'delContent'
	else return defaultColor
}

const styles = {
	textDecorationStyle: { textDecoration: 'underline' },
	marginZero: { margin: 0 },
	marginBottomZero: { marginBottom: 0 },
	paddingRight: { padding: '0px 16px 0px 0px' },
	iconWrapper: { display: 'flex', marginRight: 8 },
	customDiffStyle: diff => {
		return {
			borderTop: getItemTopBorderColor(theme, diff),
			color: getItemBackgroundColor(theme, diff)
		}
	},
	subSection: {
		backgroundColor: 'white',
		zIndex: 1
	}
}
const RequirementItem = ({ item, isDiff }) => {
	return (
		<SpecialRequirementWrapper>
			<Card testId={`requirement-item-${item.id}`}>
				<div>
					<Affix offsetTop={60}>
						<div>
							<BookMark
								diff={item.diff}
								style={{ zIndex: 2 }}
								data-testid="bookmark"
							/>
							<CardSubSection
								id={item.prefixedCaliberId}
								style={{
									...styles.customDiffStyle(item.diff),
									...styles.subSection
								}}
							>
								<HeadSection
									id={item.id}
									title={item.title}
									tags={[
										item.prefixedCaliberId,
										item.priorityText
											? item.priorityText
											: REQUIREMENT_PRIORITY_CODES[item.priority]
									]}
									chapter={item.chapter}
								/>
							</CardSubSection>
						</div>
					</Affix>
					{item.description && (
						<CardSubSection
							testId="requirement-desc-section"
							style={{
								textDecoration:
									item.diff === DIFF.DELETED ? 'line-through' : undefined
							}}
						>
							<AkaTypography
								data-testid="requirement-desc"
								variant="body2"
								color={getColor('default', item.diff)}
							>
								{ReactHtmlParser(item.description)}
							</AkaTypography>
						</CardSubSection>
					)}
					{item.note && (
						<CardSubSection
							testId="requirement-note-section"
							gray={true}
							style={{
								textDecoration:
									item.diff === DIFF.DELETED ? 'line-through' : undefined
							}}
						>
							<AkaTypography
								data-testid="requirement-note"
								variant="body3"
								color={getColor('default', item.diff)}
							>
								<AkaTypography variant="body">Hinweis</AkaTypography>
								{ReactHtmlParser(item.note)}
							</AkaTypography>
						</CardSubSection>
					)}
				</div>
				{item.regionsDiff && item.regionsDiff.length > 0 && (
					<CardSubSection gray={true}>
						<Regions regions={item.regionsDiff} isDiff={isDiff} />
					</CardSubSection>
				)}
				{!isDiff && item.regions && item.regions.length > 0 && (
					<CardSubSection gray={true}>
						<Regions regions={item.regions} isDiff={isDiff} />
					</CardSubSection>
				)}
			</Card>
		</SpecialRequirementWrapper>
	)
}
RequirementItem.propTypes = {
	item: PropTypes.shape({}).isRequired,
	isDiff: PropTypes.bool
}
RequirementItem.defaultProps = {
	isDiff: false
}

export default RequirementItem
