import React from 'react'

import { Layout } from 'antd'
import logo from 'img/AKA_portal_logo.svg'
import {
	AuthWrapper,
	StyledContent,
	StyledHeader,
	StyledLink
} from '../components/styles'
import { PasswordResetConfirm } from '../components/PasswordResetConfirm'
import { AkaTypography } from '../../../components'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../navigation'

/**
 * PasswordResetConfirmPage component
 *
 * This component renders the page for confirming password reset.
 * It includes a header with a logo, content with a password reset confirmation form,
 * and a link to navigate back to the login page.
 *
 * Components and elements used:
 * - Layout: Wraps the entire content of the page.
 * - StyledHeader: Contains the logo image.
 * - StyledContent: Contains the content of the page.
 * - AuthWrapper: Wraps the authentication-related UI elements.
 * - AkaTypography: Used for rendering text with specific typography variants.
 * - PasswordResetConfirm: Component that handles the actual password reset confirmation process.
 * - StyledLink: Provides navigation back to the login page.
 *
 * Internationalization:
 * Uses the `useTranslation` hook to access the translation function `t` for rendering localized text.
 */
const PasswordResetConfirmPage = () => {
	const [t] = useTranslation()
	return (
		<Layout>
			<StyledHeader>
				<img src={logo} width={300} alt="logo" />
			</StyledHeader>
			<StyledContent>
				<AuthWrapper>
					<AkaTypography variant="bodySegoe">
						{t('auth.passwordResetConfirmPage.headline')}
					</AkaTypography>
					<AkaTypography variant="bodySegoe">
						{t('account.changePasswordForm.validation.passwordRequirements')}
					</AkaTypography>
					<PasswordResetConfirm />
				</AuthWrapper>
				<StyledLink to={ROUTES.login}>
					<AkaTypography variant={'bodySegoe'} color={'primary'}>
						{t('auth.backToLogin')}
					</AkaTypography>
				</StyledLink>
			</StyledContent>
		</Layout>
	)
}

export default PasswordResetConfirmPage
