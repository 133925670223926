/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReportStatus, ContentCheckProofStatus } from 'generated/types'

/**
 * Compares two lists of group IDs regardless of their order
 * */
export const groupIdsAreEqual = (idList1: any, idList2: any) => {
	if (idList1.length !== idList2.length) {
		return false
	}
	for (const value of idList1) {
		if (idList2.indexOf(value) === -1) {
			return false
		}
	}
	return true
}

export const isReportSubmitted = (status: ReportStatus | undefined) =>
	status === ReportStatus.IN_REVIEW || status === ReportStatus.APPROVED

export const isContentCheckProofSubmitted = (
	status: ContentCheckProofStatus | undefined
) =>
	status === ContentCheckProofStatus.IN_REVIEW ||
	status === ContentCheckProofStatus.APPROVED

/**
 * Escapes special characters in a string to be used in a regular expression.
 *
 * @param {string} string - The string containing special characters to be escaped.
 * @return {string} The escaped string safe for use in regular expressions.
 */
export function escapeRegExp(string: string) {
	return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

/**
 * Cleans up a given GraphQL error message by removing specific substrings commonly found in error responses.
 * This function targets and removes the following substrings from the input message:
 * - 'GraphQL error: ["'
 * - 'GraphQL error: [\''
 * - '"]'
 * - "']"
 *
 * @param {string} message - The original GraphQL error message.
 * @return {string} - The cleaned error message.
 */
export const cleanUpGraphQLErrorMessage = (message: string): string =>
	message
		.replace('GraphQL error: ["', '')
		.replace("GraphQL error: ['", '')
		.replace('"]', '')
		.replace("']", '')
